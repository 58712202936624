import React, { useEffect, useState } from "react";
import { Tabs, Tab, Grid, Typography } from '@mui/material';



export default function Healthcare({data}) {
    const displayMcc = (obj) => {
        return  <Grid item container direction="row" rowSpacing={2}>
                    <Grid item width={"100%"} style={headerStyle}><Typography variant="PDTitle">DRUG SCHEDULE CODE</Typography></Grid>
                    <Grid item width={"50%"}><Typography variant="PDLabel">{obj["code"]}</Typography></Grid>
                </Grid>
    }

    const getHealthElement = (agency) => {
        let value = undefined
        data.map((elem) => {
            if(elem["agency"] === agency) {
                value = elem;
            }
        })

        return value
    }
    
    let specialDisplay = {
        "MCC" : displayMcc
    }

    let headerStyle = {borderBottom: "2px solid #f4f4f4"}
    console.log(data)
    return  data !== undefined && data.length > 0 ?
                <Grid container item direction="column" rowSpacing={4}> 
                    
                    {data.map((health) => (
                        health !== undefined ?
                            Object.keys(specialDisplay).indexOf(health["agency"]) === -1 ?
                                <Grid item container direction="row" rowSpacing={2}>
                                    <Grid item width={"50%"}><Typography variant="PDLabel">{health["agency"]}</Typography></Grid>
                                    <Grid item width={"50%"}><Typography variant="PDValue">{health["code"]}</Typography></Grid>
                                    <Grid item width={"50%"}><Typography variant="PDLabel">Description</Typography></Grid>
                                    <Grid item width={"50%"}><Typography variant="PDValue">{health["description"]}</Typography></Grid>
                                    
                                    {health["name"] !== undefined && health["value"] !== undefined ?
                                        <Grid item container direction="row" rowSpacing={2}>
                                            <Grid item width={"50%"}><Typography variant="PDLabel">{health["name"].replace(/([A-Z])/g, " $1")}</Typography></Grid>
                                            <Grid item width={"50%"}><Typography variant="PDValue">{health["value"]}</Typography></Grid>
                                        </Grid>
                                    :''}
                                </Grid>
                            : ''
                        :
                            ''
                        )
                    )}
                    {
                        Object.keys(specialDisplay).map((specialHealth) => (
                            getHealthElement(specialHealth) !== undefined ?
                                specialDisplay[specialHealth](getHealthElement(specialHealth))
                            :
                                ''
                        ))
                    }
                </Grid>
            :
            ''
}